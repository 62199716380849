.statistics *{
  font-family: 'Inter', sans-serif;
}
.statistics{

}
.statistics__card{
  // margin-bottom: 30px;
  padding:20px;
  border-radius:25px!important;
}
.statistics__cards-wrapper{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap:20px;
}
.statistics__card-wrapper{
  display:flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  gap:10px;
}
.statistics__card-title{
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #000000;
  margin-bottom: 20px;
}
body[data-layout-mode=dark] .statistics__card-title{
  color: #ffffff;
}
.statistics__card-number{
  font-size: 48px;
  font-weight: 500;
  text-align: center;
  color: #000000;
  margin-bottom: 10px;
}
body[data-layout-mode=dark] .statistics__card-number{
  color: #ffffff;
}
.statistics__card-description{
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}
body[data-layout-mode=dark] .statistics__card-description{
  color: #F5F5F5;
}
.statistics__card-link{
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color:#665bff;
}
body[data-layout-mode=dark] .statistics__card-link{
  color:#665bff;
}

.statistics-referrals{
  padding:20px;
  border-radius: 20px!important;
}
.statistics-referrals__title-wrapper{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.statistics-referrals__clients-wrapper{
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.statistics-referrals__client-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;  
  padding-bottom: 10px;
  border-bottom: 1px solid #96A0B6;
}
.statistics-referrals__client-info-wrapper{
  
}
.statistics-referrals__client-info-name{
  font-size: 14px;
  font-weight: 500;
  color:black;
}
body[data-layout-mode=dark] .statistics-referrals__client-info-name{
  color:white;
}
.statistics-referrals__client-info-city{
  font-size: 12px;
  font-weight: 400;
  color:#000000;
}
body[data-layout-mode=dark] .statistics-referrals__client-info-city{
  color:#96A0B6;
}
.statistics-referrals__client-date-wrapper{
  
}
.statistics-referrals__client-date-time{
  font-size: 12px;
  font-weight: 400;
  color:#96A0B6;
}
body[data-layout-mode=dark] .statistics-referrals__client-date-time{
  color:#96A0B6;
}
.statistics-referrals__client-date-day{
  font-size: 12px;
  font-weight: 400;
  color:#96A0B6;
}
body[data-layout-mode=dark] .statistics-referrals__client-date-day{
  color:#96A0B6;
}

.statistics-referrals__client-all-referrals{
  font-size: 14px;
  font-weight: 400;
  color:#665bff;
}
body[data-layout-mode=dark] .statistics-referrals__client-all-referrals{
  color:#665bff;
}


.statistics__small-card{
  padding:20px;
  border-radius: 10px!important;
}
.statistics__small-card-title{
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: black;
}
body[data-layout-mode=dark] .statistics__small-card-title{
  color:#96A0B6;
}
.statistics__small-card-value{
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color:#574ce5
  // background: linear-gradient(91.31deg, #574ce5 0.07%, #302994 34.61%);
}
.statistics__small-card-description{
  font-size: 14px;
  font-weight: 400;
  color: black;
}
body[data-layout-mode=dark] .statistics__small-card-description{
  color:#96A0B6;
}