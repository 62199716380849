.twofa-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-body {
    .twofa-input {
        @include r(992) {
            width: 60px;
            height: 60px;
        }

        @include r(502) {
            width: 50px;
            height: 50px;
        }

        @include r(439) {
            width: 40px;
            height: 40px;
            font-size: 1rem;
        }
    }
}

.twofa-input {
    width: 80px;
    height: 80px;
    padding: 8px;
    border-radius: 8px;
    font-size: 16px;
    text-align: center;
    margin-right: 15px;
    text-transform: uppercase;
    border: 1px solid black;
    color:black !important;

    @include r(768) {
        width: 50px;
        height: 50px;
    }

    @include r(408) {
        width: 40px;
        height: 40px;
        font-size: 1rem;
    }

    &.active {
        background-color: $custom-orange;
        color: white;
        font-size: 2rem;
    }
}

@include dark {
    .twofa-input {
        background-color: $dark-active;

        &.active {
            background-color: $custom-orange;
            color: white;
            font-size: 2rem;
        }
    }

}