.modal-steps{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
}
.step.active .step-button{
  background: linear-gradient(180deg, #6d63f6, #9851ff)!important;
  color:white!important;
} 
.step-button{
  // background: linear-gradient(180deg, #6d63f6, #9851ff)!important;
  // color:white!important;
  transition: .3s ease-in-out all;
  
  background: white!important;
  color:#6d63f6!important;
  border: 1px solid #6d63f6;
}
.create-challenge-plan__enroll{
  border-radius: 4.079px;
  background: #070337!important;
  width: 80%;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0;
  transition: .3s ease-in-out all;
  font-family: 'Inter', sans-serif;
}
.create-challenge-plan__enroll:hover{
  opacity: .8;
}
.create-challenge-plan__back{
  border-radius: 4.079px;
  background: #74788D!important;
  width: 80%;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0;
  transition: .3s ease-in-out all;
  font-family: 'Inter', sans-serif;
}
.create-challenge-plan__back:hover{
  opacity: .8;
}
