.refer-header *{
  font-family: 'Inter', sans-serif;
}
.refer-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.refer-header__title{
  font-size: 20px;
  font-weight: 600;
}
.refer-header__button{
  display: flex;
  align-items: center;
  gap:5px;
  color:#665bff
}
.refer-header__button path{
  fill:#665bff
}
.refer .nav-tabs .nav-link.active, .refer .nav-tabs .nav-item.show .nav-link{
  border-color: #665bff;
  /* border: 0; */
  border-bottom: 2px;
  color: #665bff;
  font-weight: bold;
}