.account-header *{
  font-family: 'Inter', sans-serif;
}

.account-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 138px;
  background: #f8f8ff;
  padding: 0 55px;
  border-bottom: 1px solid #dcdcdc;
  position: relative;
  background: url("../../assets/img/account_background.png");
  background-size: cover;
}
.account-header__text-wrapper{
  display: flex;
  justify-content: flex-start;
  width: 55%;
  flex-direction: column;
}
.account-header__text-title{
  color: #fff;
  font-family: Rubik, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}
.account-header__text-description{
  color: #d1d1d1;
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.account-header__buttons-wrapper{
  display: flex;
  justify-content: flex-end;
  // width: 45%;
  gap: 30px;
}
.account-header__button-free{
  --offset: 2px;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  background: rgb(55, 21, 90);
  padding: 8px 12px;
  text-align: center;
  width: 170px;
  left: 0px;
  top: 0px;
  z-index: 10;
  color: rgb(255, 255, 255);
  cursor: pointer;
  height: 40px;
  z-index: 1;
  position: relative;
  color: rgb(255, 255, 255);
  top: 2px;
  cursor: pointer;
  transition: all .3s;
}
.account-header__button-free:hover{
  opacity: .8;
}
.account-header__button-challenge{

  --offset: 2px;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  background: rgb(55, 21, 90);
  padding: 8px 12px;
  text-align: center;
  width: 170px;
  left: 0px;
  top: 0px;
  z-index: 10;
  color: #665bff !important;
  background: #fff !important;
  cursor: pointer;
  height: 40px;
  z-index: 1;
  position: relative;
  top: 2px;
  font-weight: 600 !important;
  cursor: pointer;
  transition: all .3s;
}


.account-conetnt{
  margin-top: 20px;
  margin-bottom: 20px;
}
.account-conetnt__filter-wrapper{
  display: flex;
  align-items: center;
  gap:10px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 80px;
}
.account-conetnt__filter-wrapper:first-of-type{
  margin-bottom: 10px;
}
.account-conetnt__filter-button{
  padding: 0 10px;
  color:#000000;
  transition: all .3s;
  font-weight: 600;
  padding-bottom: 15px;
  font-family: "system-ui";
}
body[data-layout-mode=dark] .account-conetnt__filter-button{
  color: #d2d2d2;
}
.account-conetnt__filter-button:hover{
  color:#007aff;
}
.account-conetnt__filter-button--active{
  font-weight: 700;
  color: #635bff !important;;
  border-bottom: 1px solid #635BFF;
  opacity: 1;
}


.account-conetnt__plans-wrapper{

}
.account-conetnt__plans-title{
  font-size: 16px;
  text-align: left;
  color: #797979;
  width: 100%;
  font-weight: 400;
  margin-bottom: 20px;
}
.account-conetnt__plan-card{
  background: #fff;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px 20px 5px 12px;
  border-radius: 10px;
  border: 1px solid #d6d6d6;
  box-shadow: 0 4px 14px 0 rgba(217,225,255,.47);
  transition: all .2s ease-in-out;
}
.account-conetnt__plan-card:hover{
  border: 1px solid #635bfd;
  background: #f3f2ff;
  box-shadow: 0.501px 0 69.189px 0 rgba(217,225,255,.38);
  z-index: 2;
  transform: scale(1.01);
}
.account-conetnt__plan-card-div{
  width:100%;
}
.account-conetnt__plan-card-title{
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #070337;
  margin-bottom: 8px;
  margin-top: 0;
}
.account-conetnt__plan-card-title--span{
  color: rgb(102, 91, 255);
  margin: 0px 5px;
}
.account-conetnt__plan-card-content-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.account-conetnt__plan-card-texts-wrapper{
  display: flex;
    gap: 20px;
}
.account-conetnt__plan-card-text-wrapper{
  padding-right: 30px;
}
.account-conetnt__plan-card-text-wrapper:first-of-type{
  border-right: 1px solid #d0cfd3;
}
.account-conetnt__plan-card-text{
  color: #4c4a67;
  font-size: 14px;
  font-weight: 500;
}
.account-conetnt__plan-card-link{
  width: 125px;
  height: 45px;
  border: 0;
  cursor: pointer;
  border-radius: 4.337px;
  background: #e4e2ff;
  box-shadow: 0 4px 8px 0 hsla(0,0%,54%,.11);
  color: #665bff;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-left: auto;
}

body[data-layout-mode=dark] .account-conetnt__plan-card-link{
  background: transparent;
  border: 1px solid #665bff;
}

@media (max-width:768px) {
  .account-header{
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    min-height: 200px;
    padding: 0 30px
  }
  .account-conetnt__plan-card-content-wrapper{
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .account-conetnt__plan-card-link{
    margin-left: initial;
  }
  .account-conetnt__filter-wrapper{
    margin-bottom: 50px;
  }
  .account-conetnt__filter-wrapper:first-of-type{
    margin-bottom: 10px;
  }
}