.promotional-materials *{
  font-family: 'Inter', sans-serif;
}
.promotional-materials{

}
.promotional-materials__card{
  // margin-bottom: 30px;
  height:300px!important;
  padding:20px;
  border-radius:25px!important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../../assets/img/promotionalMaterialsBG.png');
  background-size: cover;
}
.promotional-materials__cards-wrapper{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap:20px;
}
.promotional-materials__card-wrapper{
  display:flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  gap:10px
}
.promotional-materials__card-content-wrapper{
  width:90%;
  margin: auto;
  padding: 20px;
  border-radius: 10px!important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: black;
}
.promotional-materials__card-content-wrapper::before{
  content: '';
  z-index: 0;
  position: absolute;
  background: #00000055;
  filter: blur(10px);
  inset: 0;
}
.promotional-materials__card-content{
  z-index: 1;
}
.promotional-materials__card-content-link{
  position: absolute;
  top:10px;
  right:10px;
  padding:5px 10px;
  background-color: #665bff;
  color:white;
  font-size: 14px;
  font-weight: bold;
  z-index: 1;
}
.promotional-materials__card-title{
  margin-bottom: 20px;
  font-size: 10.33px;
  font-weight: 700;
  color:white;
  margin-bottom: 15px;
}
body[data-layout-mode=dark] .promotional-materials__card-title{
  color: #ffffff;
}
.promotional-materials__card-number{
  font-size: 8.51px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 10px;
  display:flex;
  align-items: center;
  gap:5px
}
body[data-layout-mode=dark] .promotional-materials__card-number{
  color: #ffffff;
}
.promotional-materials__card-title--span{
  width:15px;
  height:15px;
  background-color: white;
  border-radius: 2px;
}
.promotional-materials__card-description{
  font-size: 10.33px;
  font-weight: 300;
  color: white;
  margin-bottom: 20px;
}
body[data-layout-mode=dark] .promotional-materials__card-description{
  color: white;
}
.promotional-materials__card-link{
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color:#665bff;
}
body[data-layout-mode=dark] .promotional-materials__card-link{
  color:#665bff;
}
