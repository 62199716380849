.card_certificate{
    background-color: #24272E;
    width: auto;
    height: auto;
    border-radius: 10px;
}

.card_certificate2{
    background-color: #ABBDC2;
    width: auto;
    height: auto;
    border-radius: 10px;
}

.color_certification {
    color: #96A0B6 ;
}


.background_color{
    background: linear-gradient(90deg, #466189 , #0D2B59  ) !important;
    border-radius: 10px;

}

.background_color2{
    background: linear-gradient(90deg,   #D4DFE0, #D4DFE0 ) !important;
    border-radius: 10px;

}

.model_style{
    margin-top: 170px !important;
    width: 100% !important;
    background: transparent !important;
}


