.create-challenge *{
  font-family: 'Inter', sans-serif;
}
.challengs-top-bar-container{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:10px;
  border: 1px solid #cecece;
  // box-shadow: 0 4px 250px 0 #fff;
  border-radius: 24.225px;    
  background: #f2f2f7;
  padding: 5px;
  width: fit-content;
  margin:auto;
}
body[data-layout-mode=dark] .challengs-top-bar-container{
  background: transparent;
}
.challengs-top-bar-item{
  padding: 11px 19px;
  font-size: 14px;
  background-color: transparent;
  border-radius: 30px;
  transition: .1s ease-in-out all;
}
body[data-layout-mode=dark] .challengs-top-bar-item{
  color:white
}
.challengs-top-bar-item:hover{
  border: 2px solid #fff;
  background: #070337;
  // box-shadow: 0 4px 250px 0 #dddcf1;
  color: #fff;
  font-weight: 700;
  // animation: pulse 2s ease-in-out .5s infinite;
}
.challengs-top-bar-item--active{
  border: 2px solid #fff;
  background: #070337;
  // box-shadow: 0 4px 250px 0 #dddcf1;
  color: #fff;
  font-weight: 700;
  animation: pulse 2s ease-in-out .5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
      transform: scale(1.08);
  }
  100% {
      transform: scale(1);
  }
}
.plan-image-check{
  width:24px;
  height:24px;


}
.challengs-top-bar-number{
  width:32px;
  height:32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:50%;
  background-color: transparent;
  border:1px solid rgba(0, 0, 0, 0.25);
  color:rgba(0, 0, 0, 0.25);
  font-size: 16px;
}
.challengs-top-bar-item--active .challengs-top-bar-number{
  
  background-color: #FFC827;
  border-color:#FFC827;
  color:white;
  font-weight: bold;
}
.challengs-top-bar-text{
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
}
.challengs-top-bar-item--active .challengs-top-bar-text{
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
}


.create-challenge-plan-cards-wrapper{
  display: flex;
  flex-direction: column;
  gap:15px;
}
.create-challenge-plan-check-wrapper{
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  border: 1px solid #d6d6d6;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-challenge-plan-card{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px!important;
  border: 1px solid #d6d6d6;
  // padding: 15px 10px;
  padding: 21px 32px!important;
  width:80%;
  background: #fff;
  box-shadow: 0 4px 14px 0 rgba(217,225,255,.47);
  cursor: pointer;
  gap:4px;
}
.create-challenge-plan-card--active{
  background: linear-gradient(90deg, #665bff .79%, rgba(102, 91, 255, .74))!important;
}
.create-challenge-plan-cards-link{
  position: absolute;
  inset: 0;
  z-index: 1;
  background-color: transparent;
}
.create-challenge-plan-card-title{
  color: #464471;
  font-size: 15px;
  font-weight: 700;
}
.create-challenge-plan-card--active .create-challenge-plan-card-title{
  color: #fff;
}
.create-challenge-plan-card-description{
  color: #000;
  padding-top: 5px;
  font-size: 14px;
  font-weight: 700;
}
.create-challenge-plan-card--active .create-challenge-plan-card-description{
  color: #d2d2d2;
}
.create-challenge-plan-card-link{

}
.create-challenge-plan-card-price{
  color: #665bff;
  font-size: 27px;
  font-weight: 700;
}
.create-challenge-plan-card--active .create-challenge-plan-card-price{
  color: #fff;
}

.create-challenge-plan-options-card{
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px!important;
  border: 1px solid #d6d6d6;
  padding: 21px 32px!important;
  width:80%;
  height:fit-content!important;
  background: #fff;
  box-shadow: 0 4px 14px 0 rgba(217,225,255,.47);
  cursor: pointer;
}
.create-challenge-plan-card-options-card-title{
  color: #000;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 13px;
}
.create-challenge-plan-options-wrapper{
  display: flex;
  align-items: center;
  flex-wrap:wrap;
  gap:10px;
}
.create-challenge-plan-option-wrapper{
  border: 1px solid #bdb8fe;
  background: #f5f4ff;
  color: #000;
  padding: 12px 40px 12px 25px;    
  display: flex;
  align-items: center;
  gap: 4px;
}
body[data-layout-mode=dark] .create-challenge-plan-option-wrapper{
  background-color: transparent;
}
.create-challenge-plan-option-wrapper--active{
  border: 1px solid #635bfd;
  background-color: #e2e1ff;
  color: #665bff;
  font-family: 'Inter', sans-serif;
}
body[data-layout-mode=dark] .create-challenge-plan-option-wrapper--active{
  background-color: transparent;
}
.create-challenge-plan-option-input{
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #323232;
  position: relative;
}
.create-challenge-plan-option-input::before{
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:12px;
  height:12px;
  border-radius: 50%;
  background-color: #635bfd;
  opacity: 0;
  transition: .3s ease-in-out all;
}
.create-challenge-plan-option-input--radius{
  border-radius: 4px;
}
.create-challenge-plan-option-input--radius::before{
  border-radius: 2px;
}
.create-challenge-plan-option-input:checked::before{
  opacity: 1;
}
.create-challenge-plan-option-label{
  font-family: 'Inter', sans-serif;
  pointer-events: none;

}

.create-challenge-plan__total-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width:80%
}
.create-challenge-plan__total-title{
  background: linear-gradient(90deg, #080663, #514fca);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
}
body[data-layout-mode=dark] .create-challenge-plan__total-title{
  color: #635bff; 
  background: transparent;
  background-clip: initial;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
}
.create-challenge-plan__total-price{
  color: #635bff;
  font-size: 63.333px;
  font-weight: 700;
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
}
.create-challenge-plan__enroll{
  border-radius: 4.079px;
  background: #070337;
  width: 80%;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0;
  transition: .3s ease-in-out all;
  font-family: 'Inter', sans-serif;
}
.create-challenge-plan__enroll:hover{
  opacity: .8;
}












.choose-payment-method__card{
  color: #fff!important;
  padding: 30px 13px 40px!important;
  border-radius: 10px!important;
  background: linear-gradient(180deg, #6d63f6, #9851ff)!important;
  background-image: url("../../assets/img/icons/bradiend-bg.svg")!important;
  background-size: cover!important;
  background-repeat: no-repeat!important;
  background-position: 50%!important;
  margin-bottom: 30px!important;
  height: fit-content!important;

}
.choose-payment-method__card-title-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 13px;
  column-gap: 15px;
  flex-direction: column;
  align-items: flex-start;
  border-bottom-width: .94px;
  padding-bottom: 10px;
}
.choose-payment-method__card-title{
  color: #fff!important;
  font-size: 1.25rem;
}
.choose-payment-method__card-total-price{
  font-size: 1.875rem;
  padding-top: 7px;
}
.choose-payment-method__card-content-wrapper{
  display: flex;
  flex-direction: column;
  gap:10px
}
.choose-payment-method__card-item-wrapper{
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}
.choose-payment-method__card-item-label{

}
.choose-payment-method__card-item-text{

}